<template>
  <fw-layout wide back-to="/manage">
    <template #main-content>
      <PanelNotifications class="p-5" :active-filters="{}" />
    </template>
  </fw-layout>
</template>

<script>
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'

export default {
  name: 'ManageNotifications',
  components: {
    PanelNotifications
  },
  computed: {
    api() {
      return this.$store.state.api.base
    }
  }
}
</script>
